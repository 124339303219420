.text-label {
  margin: 0px;
  color: #020f59;
  font-weight: 500;
}
.custom-icon-class {
  font-size: 10px;
}

.container {
  height: 100vh;
  background: #fff;
  position: relative;
}

.class-title {
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
}

.select-report.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  vertical-align: top;
}

/* CSS */
.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(233.13deg 79.02% 48.46%) 0%,
    hsl(249.75deg 88.47% 46.45%) 8%,
    hsl(235.9deg 81.83% 41.22%) 92%,
    hsl(227.6deg 58.8% 37.28%) 100%
  );
}
.button-82-front {
  display: block;
  position: relative;
  padding: 10px 15px;
  border-radius: 12px;
  font-size: 1rem;
  color: white;
  /* background: hsl(345deg 100% 47%); */
  background: #0066ff;
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.1rem;
    padding: 8px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}

.ant-table-thead .ant-table-cell {
  background-color: rgba(236, 178, 102, 0.726) !important;
}

.center-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.highlight-text {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 10px 24px;
}
.custom-modal-background {
  background-color: greenyellow;
}
.table-row-light {
  background-color: rgb(189, 218, 233);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  cursor: pointer;
  background: rgba(224, 217, 217, 0.74) !important;
}

.text-infor span {
  padding: 0px 5px;
}

.text-name {
  font-weight: 500;
  text-decoration: underline;
}

.view-box span {
  font-size: 16px;
  font-weight: 500;
  padding: 0px 0px 5px 0px;
  color: #020f59;
}

.view-box {
  width: 100%;
  margin-top: 0.3%;
  row-gap: 1ch;
  display: flex;
  flex-direction: column;
}

.custom-col {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(183, 180, 180);
}

.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 3px 3px !important;
  overflow-wrap: break-word;
  font-size: 12px;
}

.bool-information {
  border-right: 1px solid black;
  cursor: pointer;
  font-weight: 500;
}

.bool-false-infor {
  cursor: pointer;
  font-weight: 500;
}

.custom-row {
  display: flex;
  margin-left: 1%;
  width: 55.5%;
  align-items: center;
}

.custom-title.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #f9f4f48f;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.custom-info p {
  margin: 0px;
}
.custom-info * :not(label, input) {
  padding: 0.2% 0.2%;
}

.custom-info label {
  padding: 0% 1%;
}

.custom-info {
  background: rgb(189 218 233 / 58%);
  width: 90%;
  border-radius: 16px;
}

.ant-table-wrapper table {
  /* width: 80.5%; */
  text-align: start;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed !important;
  /* margin-left: 14.5%; */
}

.custom-table p {
  margin: 2px 0px;
}

/* CSS */
.button-3 {
  appearance: none;
  background-color: #1a79ff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2b72d6ce;
}

/* .button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
} */

/* .button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
} */

/* .button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
} */

.btn-download {
  position: absolute;
  right: 3%;
  bottom: 2%;
  background: rgb(25 118 210 / 93%);
}

.custom-label label {
  font-size: 10px;
}

.custom-label div {
  padding: 0px 10px;
}

.custom-img-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 80px;
  margin-left: 1%;
}

.total-kanji-kana {
  display: flex;
  justify-content: flex-start;
  width: 96%;
  font-size: 12px;
}

.title-all-total {
  font-weight: 500;
  text-decoration: underline;
  padding: 0px 5px;
}

.custom-table {
  margin-top: 0.5vh;
  /* display: inline; */
  padding: 0px 0px 10px 0px;
  /* width: 80.5%;
  margin-left: 14.5%; */
}

table {
  border-collapse: collapse;
  /* Đặt các thuộc tính CSS khác theo ý muốn của bạn */
}
.table-filter table {
  border-collapse: collapse;
}

.table-filter th,
.table-filter td {
  border: 1px solid black;
  padding: 0px 8px;
  text-align: center;
  /* Đặt các thuộc tính CSS khác theo ý muốn của bạn */
}

.table-filter td {
  cursor: pointer;
  font-size: 14px;
  width: 40px;
}

.table-filter th {
  font-size: 14px;
}

.size-table {
  width: 90%;
  margin-left: 5%;
}

.inputTTDN.ant-input-affix-wrapper >input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.new-title {
  width: 10%;
  background: rgb(189 218 233 / 58%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.div-new-title {
  display: flex; 
  width: 90%;
  column-gap: 0.5ch
}